import '../../App.css';
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { ChakraProvider, Text, Box, Heading, Input, Button, Icon, InputGroup, InputRightAddon, Menu, MenuButton, MenuList, MenuItem, MenuDivider, useDisclosure, Badge,
  Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { CheckIcon, SearchIcon } from '@chakra-ui/icons';
import AdminAssessmentTableComponent from '../../components/table/adminassesmenttable';
import { ReactComponent as filterIcon } from '../../assets/icons/filterIcon.svg';
import { ReactComponent as companyIcon } from '../../assets/icons/companyIcon.svg';
import useAdminCheck from '../../hooks/useAdminCheck';

function Pastassessment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { username, companyName, userId, isAdmin } = useAdminCheck();
  const [searchTerm, setSearchTerm] = useState(location.state?.searchTerm || "");
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState(location.state?.searchTerm || "");
  const [sortOption, setSortOption] = useState(location.state?.sortOption || { sortBy: 'createdAt', sortOrder: '-1', status: [] });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItems, setSelectedItems] = useState(location.state?.selectedItems || []);
  const [currentPage, setCurrentPage] = useState(location.state?.currentPage || 1);
  const [currentTab, setCurrentTab] = useState("active"); 

  const memoizedSortOption = useMemo(() => sortOption, [sortOption.sortBy, sortOption.sortOrder, sortOption.status]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    setSubmittedSearchTerm(searchTerm);
    setCurrentPage(1);
    updateNavigateState(1, searchTerm, sortOption, selectedItems, currentTab);
  };

  const handleSort = (sortBy, statuses) => {
    const newSortOption = {
      ...sortOption,
      [sortBy]: statuses
    };
    setSortOption(newSortOption);
    setCurrentPage(1);
    updateNavigateState(1, searchTerm, newSortOption, selectedItems, currentTab);
  };

  const handleSelect = (item) => {
    let newSelectedItems = [];

    if (item === 'Select Both') {
      if (selectedItems.includes('Completed') && selectedItems.includes('In Process')) {
        newSelectedItems = [];
      } else {
        newSelectedItems = ['Completed', 'In Process'];
      }
    } else {
      if (selectedItems.includes(item)) {
        newSelectedItems = selectedItems.filter((i) => i !== item);
      } else {
        newSelectedItems = [...selectedItems, item];
      }
    }

    setSelectedItems(newSelectedItems);
    handleSort('status', newSelectedItems);
  };

  const isSelected = (item) =>
    selectedItems.includes(item) ||
    (item === 'Select Both' &&
      selectedItems.length === 2 &&
      selectedItems.includes('Completed') &&
      selectedItems.includes('In Process'));

  const updateNavigateState = (page, search, sort, selected, tabStatus) => {
    navigate(location.pathname, {
      state: { currentPage: page, searchTerm: search, sortOption: sort, selectedItems: selected }
    });
  };

  // Effects
  useEffect(() => {
    if (searchTerm === "") {
      handleSearchSubmit();
    }
  }, [searchTerm]);

  const handleTabChange = (index) => {
    const tabStatus = index === 0 ? "active" : "archive";
    setCurrentTab(tabStatus);
    setCurrentPage(1);
    updateNavigateState(1, submittedSearchTerm, sortOption, selectedItems, tabStatus);
  };

  return (
    <>
      <ChakraProvider>
        <Box px={{ base: 4, md: 10 }} py={10} w='100%' h='fit-content'>
          <Box
            display='flex'
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
          >
            <Heading size='md' fontSize='24px' fontWeight={700} mb={1}>
              Welcome {username}!
            </Heading>
            <Box
              px={{ base: '12px', md: '15px' }}
              py={{ base: '12px', md: '15px' }}
              mt={{ base: 2 }}
              alignSelf={{ base: 'flex-end' }}
              border='1px solid #F5F8FF'
              borderRadius='10px'
              backgroundColor='#F5F8FF'
            >
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Icon as={companyIcon} w="20px" h="18px" />
                <Text pl='5px' fontWeight='400' fontSize='12px' color='#00359E' whiteSpace='nowrap'>
                  Company Name
                </Text>
              </Box>
              <Text fontWeight='600' fontSize='20px' color='#111927' whiteSpace='nowrap'>
                {companyName}
              </Text>
            </Box>
          </Box>
          <Box>
            <Heading size='md' fontSize='20px' mb={1}>
              Past Assessments
            </Heading>
          </Box>
          <br />
          <Box w='100%' display='flex' justifyContent='space-between' flexWrap="wrap" pt={2}>
            <InputGroup
              w={{ base: '100%', md: '80%', lg: '80%', xl: '87%', '2xl': '88%' }}
              sx={{
                '@media screen and (min-width: 1800px)': {
                  width: '90%'
                },
                '@media screen and (min-width: 2300px)': {
                  width: '92%'
                }
              }}
            >
              <Input
                type='text'
                border={`1px solid ${'#D1E0FF'}`}
                focusBorderColor='#c5d7fc'
                size='md'
                placeholder='Find by Assessment Name, User Name, Company Name'
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <InputRightAddon
                as={Button}
                backgroundColor='#00359E'
                leftIcon={<SearchIcon />}
                border={`1px solid ${'#D1E0FF'}`}
                color='white'
                _hover={{ bg: "#00359E" }}
                onClick={() => handleSearchSubmit()}
              >
                Search
              </InputRightAddon>
            </InputGroup>
            <Menu isOpen={isOpen} onClose={onClose} closeOnSelect={false}>
              <MenuButton
                as={Button}
                rightIcon={
                  <Icon
                    as={filterIcon}
                    w="16px"
                    h="20px"
                    style={{ color: selectedItems.length > 0 ? 'white' : '#111927', stroke: selectedItems.length > 0 ? 'white' : '#111927' }}
                  />
                }
                py={'8px'}
                px={'15px'}
                bg={selectedItems.length > 0 ? "#2970FF" : "#F5F8FF"}
                border="1px solid #D1E0FF"
                color={selectedItems.length > 0 ? "white" : "black"}
                _hover={{ bg: selectedItems.length > 0 ? "#2970FF" : "#F5F8FF" }}
                _active={{ bg: selectedItems.length > 0 ? "#2970FF" : "#F5F8FF" }}
                _focus={{ bg: selectedItems.length > 0 ? "#2970FF" : "#F5F8FF" }}
                textAlign="right"
                h="fit-content"
                mt={{ base: 2, md: 0 }}
                onClick={isOpen ? onClose : onOpen}
              >
                {selectedItems.length > 0 && (
                  <Badge colorScheme="white" bg="white" color="black" borderRadius="full" px='6px' py='2px' mr={2}>
                    {selectedItems.length}
                  </Badge>
                )}
                Status
              </MenuButton>
              <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
                {['Completed', 'In Process', 'Select Both'].map((item, index, array) => (
                  <React.Fragment key={item}>
                    <MenuItem
                      borderRadius='5px'
                      bg={isSelected(item) ? "#EFF4FF" : "white"}
                      _hover={{ bg: "#EFF4FF" }}
                      color='#00359E'
                      onClick={() => handleSelect(item)}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      {item}
                      {isSelected(item) && (
                        <Box as="span" color="#00359E" ml={2}>
                          <CheckIcon boxSize={3} />
                        </Box>
                      )}
                    </MenuItem>
                    {index < array.length - 1 && (
                      <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                    )}
                  </React.Fragment>
                ))}
              </MenuList>
            </Menu>
          </Box>
          <br />
          <Box>
            <Tabs variant="line" index={currentTab === "active" ? 0 : 1}  onChange={handleTabChange}>
              <TabList>
                <Tab flex="1" _selected={{ color: "#00359E", borderColor: "#00359E" }} >
                  Active
                </Tab>
                <Tab flex="1" _selected={{ color: "#00359E", borderColor: "#00359E" }} >
                  Archive
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <AdminAssessmentTableComponent
                    searchTerm={submittedSearchTerm}
                    sortOption={memoizedSortOption}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    updateNavigateState={updateNavigateState}
                    isAdmin={isAdmin}
                    userId={userId}
                    currentTab={currentTab}
                  />
                </TabPanel>
                <TabPanel>
                  <AdminAssessmentTableComponent
                    searchTerm={submittedSearchTerm}
                    sortOption={memoizedSortOption}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    updateNavigateState={updateNavigateState}
                    isAdmin={isAdmin}
                    userId={userId}
                    currentTab={currentTab}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Pastassessment;
