import '../../App.css';
import React from "react";
import { ChakraProvider } from '@chakra-ui/react'
import {  Button, Text, Box as ChakraBox, Heading, useToast,Icon } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { ReactComponent as shield} from '../../assets/icons/shield.svg'
import InputField from '../../components/form/inputField';
import PasswordField from '../../components/form/passwordField';
import axios from 'axios';


function Login() {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState({});
  const toast = useToast()
  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();

  const validate = (field, value) => {
    let error = '';

    switch (field) {
      case 'email':
        const emailRegex = /^(?!.*(\.\.|@\.|\.@|@-))^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!value) {
          error = 'Email is required';
        } else if (!emailRegex.test(value)) {
          error = 'Email is invalid';
        }
        break;
      case 'password':
        if (!value) {
          error = 'Password is required';
        } else if (value.length < 6) {
          error = 'Password must be at least 6 characters';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return error;
  };

  const handleBlur = (field) => (e) => {
    validate(field, e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = [
      'email',
      'password',
    ];

    const validationErrors = {};

    // Validate individual fields
    fields.forEach((field) => {
      const value = eval(field);
      const error = validate(field, value);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      emailId: email,
      password: password,
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, data);
      const token = response.data.data.token;
      const refreshToken = response.data.data.refreshToken;
      setEmail('');
      setPassword('');
      login(token, refreshToken)
      navigate('/pastAssessment')
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response && error.response.data.message === "No account found for this email address") {
        setErrors({ email: 'Incorrect email address' });
      } else if (error.response && error.response.data.message === "Contact admin for login") {
        setErrors({ email: 'Contact admin for login' });
      } else if (error.response && error.response.data.message === "Invalid password") {
        setErrors({ password: 'Incorrect password' });
      } else {
        console.log(error.response.data)
      }
    }
  }

  return (
    <div className="login">
      {location.state && location.state.success && (
          <ChakraBox display="flex" justifyContent='center' alignItems="center" mb={20}>
            <ChakraBox width='fit-content'  borderRadius="10px" bg="#ECFDF3" p={12} display="flex" justifyContent='center' alignItems="center">
              <ChakraBox width='fit-content'>
                <Icon as={shield} alt="Success Icon" w="24px" h="24px" mr={3} />
              </ChakraBox>
              <ChakraBox>
                <Text color='#027A48' fontWeight='500' fontSize='16px'>Your Password has been changed successfully!</Text>
                <Text color='#6C737F' fontWeight='500' fontSize='12px'>You can login below now with new password</Text>
              </ChakraBox>
            </ChakraBox>
          </ChakraBox>
        )}
      <div className='rounded-box'>
        <ChakraProvider>
          <Heading as="h1" fontWeight='700' fontSize='24px' color={'black'}>Sign in to your account</Heading>
          <br />
          <Text as="h6" fontWeight='500' fontSize='16px' color={'#00359E'} >Please enter your email and password to continue </Text>
          <br />
          <ChakraBox textAlign={'left'} width={'100%'} >
            <form onSubmit={handleSubmit}>
              <ChakraBox mb="5">
                <InputField
                  label="Email"
                  value={email}
                  error={errors.email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => handleBlur('email')}
                  placeholder="Enter your Email address"
                  // isRequired
                />
              </ChakraBox>
              <ChakraBox mb="5">
                <PasswordField
                  label="Password"
                  value={password}
                  error={errors.password}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={() => handleBlur('password')}
                  placeholder="Enter your password"
                  // isRequired
                />
              </ChakraBox>
              <Text bg='#EFF4FF' width='fit-content' fontSize='14px' fontWeight="400" color='#2970FF' cursor='pointer' onClick={() => navigate('/forgetPassword')}>
                Forgot password
              </Text>
              <br/>
              <Button type="submit" bg="#2970FF" color='white' variant='solid' width={'100%'} _hover={{ bg: '#7044c4' }}>
                Sign in
              </Button>
            </form>
          </ChakraBox>
        </ChakraProvider>
      </div>
    </div>
  );
}

export default Login;
