// components/upload/FileUpload.jsx
import React, { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Icon,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import DropboxChooser from 'react-dropbox-chooser';
import { ReactComponent as docusIcons } from '../../assets/icons/docusIcons.svg';
import { ReactComponent as dropboxIcons } from '../../assets/icons/dropboxIcons.svg';
import { ReactComponent as circleCloseIcon } from '../../assets/icons/circleCloseIcon.svg';
import { ReactComponent as fileIcon } from '../../assets/icons/fileIcon.svg';
import { useDropzone } from 'react-dropzone';
import GoogleDrivePicker from 'google-drive-picker';

const FileUpload = ({ files, setFiles, errors, setErrors }) => {
  const DROPBOX_APP_KEY = "7xp4y3eh3i8e08h"
  const [isDragActive, setIsDragActive] = React.useState(false);

  const [authToken, setAuthToken] = React.useState('');
  const [openPicker, authRes] = GoogleDrivePicker();

  useEffect(() => {
    if (authRes) {
      setAuthToken(authRes.access_token);
    }
  }, [authRes]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    // noClick: true,
    onDrop: (acceptedFiles, rejectedFiles) => {
      handleFileDrop(acceptedFiles, rejectedFiles);
    },
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
  });

  const handleFileDrop = (acceptedFiles, rejectedFiles) => {
    const filteredAcceptedFiles = acceptedFiles.filter(
      (file) => file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
  
    let newErrors = '';
    if (filteredAcceptedFiles.length !== acceptedFiles.length) {
      newErrors = 'Only PDF & DOCX files are accepted.';
    }
  
    if (rejectedFiles.length > 0) {
      newErrors = 'Only PDF & DOCX files are accepted.';
    }
  
    setErrors(newErrors);
  
    if (filteredAcceptedFiles.length > 0) {
      const duplicateFiles = [];
      filteredAcceptedFiles.forEach((newFile) => {
        const isDuplicate = files.some(
          (file) => file.name === newFile.name && file.size === newFile.size
        );
        if (isDuplicate) {
          duplicateFiles.push(newFile.name);
        } else {
          setFiles((prevFiles) => [...prevFiles, newFile]);
        }
      });
  
      if (duplicateFiles.length > 0) {
        setErrors(`${duplicateFiles.join(', ')} file(s) are already uploaded.`);
      }
    }
  };

  const handleGoogleDriveUpload = () => {
    openPicker({
      clientId: "508023560665-6q8m49jcbntmfnc7sf8mv1ousstp3248.apps.googleusercontent.com",
      developerKey: "AIzaSyCguQfzGLPRv6e2xUc2_xHegtVG7ZObNy8",
      viewMimeTypes:"application/pdf,application/vnd.google-apps.document",
      token: authToken,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button');
        } else if (data.docs && data.docs.length > 0) {
          const googleDriveFiles = data.docs.map((doc) => ({
            name: doc.name,
            link: doc.url,
            mimeType: doc.mimeType,
            size: doc.sizeBytes,
          }));

          const duplicateFiles = [];
          const nonDuplicateFiles = [];

          googleDriveFiles.forEach((newFile) => {
            const isDuplicate = files.some(
              (file) => file.name === newFile.name && file.size === newFile.size
            );
            if (isDuplicate) {
              duplicateFiles.push(newFile.name);
            } else {
              nonDuplicateFiles.push(newFile);
            }
          });

          if (duplicateFiles.length > 0) {
            setErrors(`${duplicateFiles.join(', ')} file is already uploaded`);
          } else {
            setErrors('');
          }

          setFiles((prevFiles) => [...prevFiles, ...nonDuplicateFiles]);
        }
      },
    });
  };

  const handleDropboxUpload = (inputFiles) => {
    const dropboxFiles = inputFiles.map((file) => ({
      name: file.name,
      link: file.link,
      mimeType: file.mimeType,
      size: file.bytes,
    }));
    const duplicateFiles = [];
    const nonDuplicateFiles = [];

    dropboxFiles.forEach((newFile) => {
      const isDuplicate = files.some(
        (file) => file.name === newFile.name && file.size === newFile.size
      );
      if (isDuplicate) {
        duplicateFiles.push(newFile.name);
      } else {
        nonDuplicateFiles.push(newFile);
      }
    });

    if (duplicateFiles.length > 0) {
      setErrors(`${duplicateFiles.join(', ')} file is already uploaded`);
    } else {
      setErrors('');
    }

    setFiles((prevFiles) => [...prevFiles, ...nonDuplicateFiles]);
  };

  const removeFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  return (
    <Box>
      <Box
        {...getRootProps()}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        border="2px dashed"
        borderColor={errors ? '#F04438' : '#2970FF'}
        borderRadius="lg"
        bg={errors ? '#FEF3F2' : '#EFF4FF'}
        p={4}
        w="100%"
        h="200px"
        cursor="pointer"
      >
        <input type="file" accept="application/pdf" {...getInputProps()} />
        <ButtonGroup size="sm" isAttached variant="outline" zIndex={1} mb={2}>
          <Button
            bg="white"
            _hover={{ bg: 'white' }}
            borderColor="#D1E0FF"
            color="black"
            leftIcon={<Icon as={fileIcon} w="13px" h="17px" />}
            variant="outline"
            onClick={(e) => {
              open();
              e.stopPropagation();
            }}
          >
            Choose Files
          </Button>
          <Menu>
            <MenuButton
              w="fit-content"
              bg="white"
              borderColor="#D1E0FF"
              _hover={{ bg: 'white' }}
              as={Button}
              onClick={(e) => e.stopPropagation()}
            >
              <ChevronDownIcon />
            </MenuButton>
            <MenuList
              width="fit-content"
              minWidth="0"
              p={4}
              borderColor="#EFF4FF"
              borderRadius="12px"
            >
              <MenuItem
                borderRadius="5px"
                bg="white"
                _hover={{ bg: '#EFF4FF' }}
                fontWeight="400"
                fontSize="14px"
                color="#00359E"
                onClick={(e) => {
                  open();
                  e.stopPropagation();
                }}
              >
                From Device
              </MenuItem>
              <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />
              <DropboxChooser
                appKey={DROPBOX_APP_KEY}
                success={(e) => handleDropboxUpload(e)}
                cancel={() => console.log('closed')}
                multiselect
                extensions={['.pdf', '.doc', '.docx']}
              >
                <MenuItem
                  icon={<Icon as={dropboxIcons} w="24px" h="24px" />}
                  borderRadius="5px"
                  bg="white"
                  _hover={{ bg: '#EFF4FF' }}
                  fontWeight="400"
                  fontSize="14px"
                  color="#00359E"
                >
                  From Dropbox
                </MenuItem>
              </DropboxChooser>
              <MenuDivider borderWidth="1px" borderColor="#D1E0FF" />
              <MenuItem
                icon={<Icon as={docusIcons} w="24px" h="24px" />}
                borderRadius="5px"
                bg="white"
                _hover={{ bg: '#EFF4FF' }}
                fontWeight="400"
                fontSize="14px"
                color="#00359E"
                onClick={(e) => {
                  handleGoogleDriveUpload();
                  e.stopPropagation();
                }}
              >
                From Google Drive
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
        <Text fontSize="xs" fontWeight="normal" color="gray.600">
          {isDragActive ? 'Drop the files here ...' : 'or drag and drop files here'}
        </Text>
      </Box>
      {errors && (
        <Text color="red.500" fontSize="sm">
          {errors}
        </Text>
      )}
      <Box display='flex' justifyContent='space-between'>
        {files.length > 0 && (
          <Text color="#2970FF" fontSize="sm">
            Upload Files Count: {files.length}
          </Text>
        )}
        <Text color="#2970FF" fontSize="sm">
          Only .pdf and .docx format can be uploaded
        </Text>
      </Box>
      {files.length > 0 && (
        <Box
          p={3}
          display="flex"
          flexWrap="wrap"
          overflow="hidden"
          justifyContent="flex-start"
          alignItems="center"
          gap={2}
        >
          {files.map((file, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
              py="3px"
              px="10px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              border="1px solid #D1E0FF"
              borderRadius="5px"
              backgroundColor="#D1E0FF"
            >
              <Text fontSize="12px" color="#004EEB" fontWeight="400" pr="2">
                {file.name}
              </Text>
              <Icon
                as={circleCloseIcon}
                w="16px"
                h="16px"
                cursor="pointer"
                onClick={() => removeFile(file)}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
