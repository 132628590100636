import React, { useEffect } from 'react';
import { ChakraProvider, Box, Grid, GridItem, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading, useToast, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import InputField from '../../components/form/inputField';
import PasswordField from '../../components/form/passwordField';
import SelectField from '../../components/form/selectField';
import useFormValidation from '../../hooks/useFormValidation';
import useIndustryTypes from '../../hooks/useIndustryType';
import { get, post } from '../../services/api';

const Createuser = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const industryOptions = useIndustryTypes();
  const roleOptions = ['Admin', 'User'];

  const { values, errors, handleChange, handleBlur, isFormValid, setErrors } = useFormValidation({
    name: '',
    phoneNumber: '',
    email: '',
    companyName: '',
    numberOfEmployees: '',
    password: '',
    confirmPassword: '',
    industryType: '',
    role: '',
  });

  const handleSubmit = async () => {
    if (!isFormValid()) return;

    const data = {
      userName: values.name,
      phoneNumber: values.phoneNumber,
      emailId: values.email,
      companyName: values.companyName,
      companySize: values.numberOfEmployees,
      industryType: values.industryType,
      role: values.role,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };

    try {
      const response = await post(`users/user`, data);
      toast({
        title: response.data.message,
        description: 'User has been created successfully',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
        onCloseComplete: () => {
          navigate('/user');
        },
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response) {
        const errorMsg = error.response.data.message;
        if (errorMsg === 'Email is already registered') {
          setErrors({ email : 'Email is already registered'});
        } else if (errorMsg === 'Phone number is already registered') {
          setErrors({ phoneNumber: 'Phone number is already registered' })
        } else if (errorMsg === 'Passwords do not match') {
          setErrors({ confirmPassword : 'Passwords do not match' })
        }
      }
    }
  };

  return (
    <ChakraProvider>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex="1" overflowY="auto" display="flex" flexDirection="column" justifyContent="space-between" w="100%" h="fit-content">
          <Box py={{ base: 7, md: 10 }} px={{ base: 5, md: 10 }}>
            <Breadcrumb separator=">" color="#818589" fontWeight="400" fontSize="sm" mb={2}>
              <BreadcrumbItem>
                <BreadcrumbLink href="/user">User</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href="/createUser">Add User</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Box>
              <Heading size="md" fontSize={{ base: '20px', md: '24px' }} fontWeight="700" mb={2}>
                Create User
              </Heading>
            </Box>
            <Box mb={5} w="100%" bg="#F5F8FF" p={{ base: '20px', md: '30px' }} h="fit-content" border="1px solid #D1E0FF" borderRadius="12px">
              <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={{ base: 1, md: 2, lg: 5 }}>
                <GridItem w="100%" h="fit-content">
                  <InputField
                    label="Name of user"
                    value={values.name}
                    error={errors.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    onBlur={() => handleBlur('name')}
                    placeholder="Name of a user"
                    isRequired
                  />
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <InputField
                    label="Phone Number (e.g.: 98XXXXXXXX)"
                    type="number"
                    value={values.phoneNumber}
                    error={errors.phoneNumber}
                    onChange={(e) => handleChange('phoneNumber', e.target.value)}
                    onBlur={() => handleBlur('phoneNumber')}
                    placeholder="Enter Phone number"
                    isRequired
                  />
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <InputField
                    label="Email Address"
                    value={values.email}
                    error={errors.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    onBlur={() => handleBlur('email')}
                    placeholder="Enter Email address"
                    isRequired
                  />
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <InputField
                    label="Name of company"
                    value={values.companyName}
                    error={errors.companyName}
                    onChange={(e) => handleChange('companyName', e.target.value)}
                    onBlur={() => handleBlur('companyName')}
                    placeholder="Enter Company name"
                    isRequired
                  />
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <InputField
                    label="Company size"
                    type="number"
                    value={values.numberOfEmployees}
                    error={errors.numberOfEmployees}
                    onChange={(e) => handleChange('numberOfEmployees', e.target.value)}
                    onBlur={() => handleBlur('numberOfEmployees')}
                    placeholder="Enter size of the company"
                    isRequired
                  />
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <SelectField
                    label="Select the industry type"
                    options={industryOptions}
                    selectedOption={values.industryType}
                    setSelectedOption={(option) => handleChange('industryType', option)}
                    error={errors.industryType}
                    onBlur={() => handleBlur('industryType')}
                    isRequired
                  />
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <PasswordField
                    label="Password"
                    value={values.password}
                    error={errors.password}
                    onChange={(e) => handleChange('password', e.target.value)}
                    onBlur={() => handleBlur('password')}
                    placeholder="Enter your password"
                    isRequired
                  />
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <PasswordField
                    label="Confirm Password"
                    value={values.confirmPassword}
                    error={errors.confirmPassword}
                    onChange={(e) => handleChange('confirmPassword', e.target.value)}
                    onBlur={() => handleBlur('confirmPassword')}
                    placeholder="Enter confirm password"
                    isRequired
                  />
                </GridItem>
                <GridItem w="100%" h="fit-content">
                  <SelectField
                    label="Select the role"
                    options={roleOptions}
                    selectedOption={values.role}
                    setSelectedOption={(option) => handleChange('role', option)}
                    error={errors.role}
                    onBlur={() => handleBlur('role')}
                    isRequired
                  />
                </GridItem>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" py={4} px={5} w="100%" bg="white" borderTop="1px solid #D1E0FF">
          <Button
            bg={isFormValid() ? '#2970FF' : '#F04438'}
            color="white"
            variant="solid"
            width={{ base: 'fit-content', md: '15%', lg: '18%', xl: '15%' }}
            _hover={{ bg: isFormValid() ? '#7044c4' : '#F04438' }}
            onClick={handleSubmit}
            isDisabled={!isFormValid()}
            maxW="100%"
          >
            Create user
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default Createuser;