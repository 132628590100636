import React, { useEffect, useState } from "react";
import {
  ChakraProvider, Text, Box, Heading, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, Textarea, UnorderedList, ListItem
} from '@chakra-ui/react';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { useParams } from 'react-router-dom';
import { get } from "../../services/api";

// Modal Component
const RemarksModal = ({ isOpen, onClose, headerText, bodyText }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent my={4} p={4}>
      <ModalHeader color='black' fontWeight='700' fontSize='18px' pb={2}>{headerText}</ModalHeader>
      <ModalBody >
        <Text color='#6C737F' fontWeight='300' fontSize='16px' textAlign='justify' lineHeight='22px'>{bodyText}</Text>
        <br/>
        <Text mb={1} fontWeight="500" fontSize='16px' color="#111927" >Add Remarks</Text>
        <Textarea p={2} border='1px solid #D1E0FF' bg='#F5F8FF' borderRadius='10px' focusBorderColor='#D1E0FF' placeholder='Here is a text' size='sm' resize={'none'} height='180px'/>
        </ModalBody>
      <ModalFooter width='100%' display='flex' justifyContent='space-between' alignItems='center'>
        <Button variant='ghost' bg='#EFF4FF'_hover={{ bg: '#EFF4FF' }} color='#2970FF'  width='47.5%' onClick={onClose}>Cancel</Button>
        <Button bg="#2970FF" color='white' variant='solid' width='47.5%' _hover={{ bg: '#7044c4' }} >
          Submit
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

// Indicator Box Component
const IndicatorBox = ({ techIndicatorId, techIndicator, maturityLevel, strengthSummary, improvementsNeeded }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const statusColors = {
    "Good": "#12B76A",
    "Average": "#FED137",
    "Excellent": "#55B40C",
    "Needs Attention":"#D72626",
    "Fair":"#F26D24"
};

  return (
    <Box border='1px solid' borderColor="#D1E0FF" height='fit-content' borderRadius='20px' mb={6}>
      {/* <Box p={4} borderBottom='1px solid' borderColor="#D1E0FF" bg='#F5F8FF'  borderTopRadius='20px'>
        <Text color='Black' fontSize="16px" fontWeight='700' textAlign='justify'>{title}</Text>
      </Box> */}
      <Box width='100%' borderTopRadius='20px' borderBottom='1px solid' bg='#F5F8FF' borderColor="#D1E0FF" display='flex' alignItems='center' justifyContent='center'>
        <Box p={4} borderRight='1px solid' borderColor="#D1E0FF" width='50%'>
          <Text color='Black' fontSize="16px" fontWeight='600' textAlign='justify'> {techIndicator} </Text>
        </Box>
        <Box width={'50%'} p={4}>
          <Text color='black' fontWeight='500' fontSize="14px" textAlign='justify'>Maturity Level: <Text as='span' fontWeight='500' fontSize="14px" color={statusColors[maturityLevel]}> {maturityLevel} </Text> </Text>
        </Box>
      </Box>
      <Box p={4}>
        {strengthSummary.length > 0 && (
          <Box pb={2}>
            <Text color='black' fontWeight='500' fontSize="14px" textAlign='justify'>
              Strength Summary
            </Text>
            <Box px={6} py={1}>
              <UnorderedList>
                {strengthSummary.map((point, index) => (
                  <ListItem key={index} fontSize="14px" fontWeight="500" color='#3e424a' lineHeight='21px' textAlign={'justify'}>{point}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          </Box>
        )}
        {improvementsNeeded.length > 0 && (
          <Box pb={2}>
            <Text color='black' fontWeight='500' fontSize="14px" textAlign='justify'>
              Improvements Needed
            </Text>
            <Box px={6} py={1}>
              <UnorderedList>
                {improvementsNeeded.map((point, index) => (
                  <ListItem key={index} fontSize="14px" fontWeight="500" color='#3e424a' lineHeight='21px' textAlign={'justify'}>{point}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          </Box>
        )}
      </Box>
      {/* <Box p={4} borderTop='1px solid' borderColor="#D1E0FF">
        <Box onClick={onOpen} cursor='pointer' width='fit-content' height='fit-content'display='flex' justifyItems={'flex-start'} alignItems={'center'}>
            <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 20, color: '#2970FF' }} />
            <Text pl={1} pb={1} color='#2970FF' fontSize="14px" fontWeight='500' textAlign={'justify'}>Remark</Text>
        </Box>
      </Box> */}
      {/* <RemarksModal isOpen={isOpen} onClose={onClose} headerText={remarksHeader} bodyText={remarksBody} /> */}
    </Box>
  );
};

function TechIndicator() {

  const { reportId = 1 } = useParams();
  const [ indicators, setIndicators] = useState([]);

  const fetchData = async () => {
    try {
      const response = await get(`report/techindicator/${reportId}`);
      console.log(response)
      setIndicators(response.data.data.techIndicators)
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [reportId]);

  // const indicators = [
  //   {
  //     title: "ESG Strategy & Execution",
  //     businessrelvance: "Normal",
  //     maturitylevel:"Good",
  //     details: "The following table summarizes the company's Corsis Scores for each track that was evaluated, including a comparison of each score to other companies that Crosslake has evaluated within the same industry group. Industry leading scores are those in the top 16th percentile and are highlighted in green. Industry lagging are those in the bottom 16th percentile and are highlighted in red. Scores colored in light-green, yellow and orange are grouped within percentiles falling between leader and laggard scores. See the appendix for additional details on color and percentile distributions.",
  //     remarksHeader: "Environmental, Social and Governance (General)- IT infrastructure",
  //     remarksBody: "The following table summarizes the company's Corsis Scores for each track that was evaluated, including a comparison of each score to other companies that Crosslake has evaluated within the same industry group. Industry leading scores are those in the top 16th percentile and are highlighted in green. Industry lagging are those in the bottom 16th percentile and are highlighted in red. Scores colored in light-green, yellow and orange are grouped within percentiles falling between leader and laggard scores. See the appendix for additional details on color and percentile distributions."
  //   },
  //   {
  //     title: "Business Ethics",
  //     businessrelvance: "Normal",
  //     maturitylevel:"Good",
  //     details: "The following table summarizes the company's Corsis Scores for each track that was evaluated, including a comparison of each score to other companies that Crosslake has evaluated within the same industry group. Industry leading scores are those in the top 16th percentile and are highlighted in green. Industry lagging are those in the bottom 16th percentile and are highlighted in red. Scores colored in light-green, yellow and orange are grouped within percentiles falling between leader and laggard scores. See the appendix for additional details on color and percentile distributions.",
  //     remarksHeader: "Environmental, Social and Governance (General)",
  //     remarksBody: "The following table summarizes the company's Corsis Scores for each track that was evaluated, including a comparison of each score to other companies that Crosslake has evaluated within the same industry group. Industry leading scores are those in the top 16th percentile and are highlighted in green. Industry lagging are those in the bottom 16th percentile and are highlighted in red. Scores colored in light-green, yellow and orange are grouped within percentiles falling between leader and laggard scores. See the appendix for additional details on color and percentile distributions."
  //   }
  // ];

  return (
    <ChakraProvider>
      <Box px={10} py={10} w='100%' h='fit-content' overflowY='scroll'>
        <Heading fontSize="24px" fontWeight='700' color="black" mb={1}>Tech Indicators</Heading>
        <br />
        <Box>
          {/* <Box pb={3}>
            <Text fontSize="18px" fontWeight='500' color="black">ESG Strategy</Text>
          </Box> */}
          {indicators.map((indicator, index) => (
            <IndicatorBox
              key={index}
              techIndicatorId ={indicator.techIndicatorId}
              techIndicator={indicator.techIndicator}
              maturityLevel={indicator.maturityLevel}
              strengthSummary={indicator.strengthSummary}
              improvementsNeeded={indicator.improvementsNeeded}
            />
          ))}
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default TechIndicator;
