import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ userComponent: UserComponent, adminComponent: AdminComponent, allowedRoles, ...rest }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(user.type)) {
    return <Navigate to="/not-authorized" />;
  }

  if (user.type === 'Admin' && AdminComponent) {
    return <AdminComponent {...rest} />;
  }

  return <UserComponent {...rest} />;
};

export default ProtectedRoute;
