import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem, MenuDivider, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DeleteConfirmationModal from '../modals/deleteConfirmModal';
import { useNavigate } from 'react-router-dom';
import { post, remove, patch } from '../../services/api';

const PopoverMenu = ({ reportId, assessmentId, currentTab, refreshAssessmentData }) => {
    const navigate = useNavigate();

    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const {
      isOpen: isDeleteOpen,
      onOpen: onDeleteOpen,
      onClose: onDeleteClose,
    } = useDisclosure();
  
    const viewReport = async (reportId) => {
      navigate(`/reports/${reportId}`);
    };
  
    const downloadReport = async (reportId) => {
        setIsLoading(true);
        try {
            const response = await post(`report/downloadReport/${reportId}`, {}, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report_${reportId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading report:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const downloadScore = async (reportId) => {
      setIsLoading(true);
      try {
          const response = await post(`report/${reportId}/downloadScoreSheet`, {}, { responseType: 'blob' });
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `excel_${reportId}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
      } catch (error) {
          console.error('Error downloading score:', error);
      } finally {
          setIsLoading(false)
      }
    };

    const deleteAssessment = async (assessmentId) =>{
      try {
        const response = await remove(`assessment/${assessmentId}`);
        // Show success toast
        toast({
          title: response.data.message,
          description: 'Assessment has been deleted successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        refreshAssessmentData()
      } catch (error) {
        console.error('Error submitting form:', error);
        if(error.response.status === 400) {
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        } else if(error.response.status === 404){
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        } else {
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }
    }

    const updateShowStatus = async (assessmentId, status) =>{
      try {
        const response = await patch(`assessment/${assessmentId}/status`, { showStatus: status });
        // Show success toast
        toast({
          title: response.data.message,
          description: 'Assessment has been updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        refreshAssessmentData()
      } catch (error) {
        console.error('Error submitting form:', error);
        if(error.response.status === 400) {
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        } else if(error.response.status === 404){
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        } else {
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }
    }

    const handleDeleteConfirm = () => {
      deleteAssessment(assessmentId);
      onDeleteClose();
    }
  
    return (
        <>
          {isLoading ? (
            <Spinner size="sm" color='#00359E' />
          ) : (
            <Menu size="sm">
              <MenuButton
                as={Button}
                bg="white"
                color="black"
                height="1.2rem"
                _hover={{ bg: "white" }}
                _active={{ bg: "white" }}
                _focus={{ bg: "white" }}
              >
                <MoreVertRoundedIcon />
              </MenuButton>
              <MenuList width="fit-content" minWidth="0" p={4} borderColor='#EFF4FF' borderRadius='12px'>
                <MenuItem
                  borderRadius='5px'
                  bg="white"
                  _hover={{ bg: "#EFF4FF" }}
                  color='#00359E'
                  onClick={() => viewReport(reportId)}
                >
                  View Report
                </MenuItem>
                <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                <MenuItem
                  borderRadius='5px'
                  bg="white"
                  _hover={{ bg: "#EFF4FF" }}
                  color='#00359E'
                  onClick={() => downloadReport(reportId)}
                >
                  Download Report
                </MenuItem>
                <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                {currentTab === "active" ? (
                  <>
                    <MenuItem
                      borderRadius='5px'
                      bg="white"
                      _hover={{ bg: "#EFF4FF" }}
                      color='#00359E'
                      onClick={() => updateShowStatus(assessmentId, 'archive')}
                    >
                      Archive Assessment
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      borderRadius='5px'
                      bg="white"
                      _hover={{ bg: "#EFF4FF" }}
                      color='#00359E'
                      onClick={() => updateShowStatus(assessmentId, 'active')}
                    >
                      Activate Assessment
                    </MenuItem>
                  </>
                )}
                <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                <MenuItem
                  borderRadius='5px'
                  bg="white"
                  _hover={{ bg: "#EFF4FF" }}
                  color='#00359E'
                  onClick={() => downloadScore(reportId)}
                >
                  Download Score
                </MenuItem>
                <MenuDivider borderWidth="1px" borderColor='#D1E0FF' />
                <MenuItem
                  borderRadius='5px'
                  bg="white"
                  _hover={{ bg: "#FEF3F2" }}
                  color='#F04438'
                  onClick={onDeleteOpen}
                >
                  Delete Assessment
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          <DeleteConfirmationModal
            isOpen={isDeleteOpen}
            onClose={onDeleteClose}
            onConfirm={handleDeleteConfirm}
            message="assessment"
          />
        </>
    );
};

export default PopoverMenu;