import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  ChakraProvider, Box, Text, VStack, IconButton, Icon, useDisclosure, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
} from '@chakra-ui/react';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { ReactComponent as LogoutIcon } from '../../assets/icons/Logout.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/homeIcon.svg';
import { ReactComponent as createAssessment } from '../../assets/icons/createAssessment.svg';
import { ReactComponent as profileIcon } from '../../assets/icons/profileIcon.svg';
import { ReactComponent as user } from '../../assets/icons/user.svg';
import { ReactComponent as feedback } from '../../assets/icons/feedback.svg';
import { ReactComponent as reportManagement } from '../../assets/icons/reportManagement.svg';
import { ReactComponent as reports } from '../../assets/icons/reports.svg';
import { ReactComponent as assessmentFindings } from '../../assets/icons/assessmentFindings.svg';
import { ReactComponent as techIndicators } from '../../assets/icons/techIndicators.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../../context/AuthContext';
import useAdminCheck from '../../hooks/useAdminCheck';
import MenuItem from './menuitem';

const Sidebar = ({ isCollapsed, toggleSidebar, toggleSubSidebar, toggleSubSidebarOthers, lastReportId }) => {
  const { logout } = useAuth();
  const { isAdmin } = useAdminCheck();
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(() => parseInt(localStorage.getItem('selectedMenuIndex')) || 0);
  const [selectedsub, setSelectedsub] = useState(() => parseInt(localStorage.getItem('selectedSubMenuIndex')) || 0);
  const [selectedTechIndicatorSlug, setSelectedTechIndicatorSlug] = useState(
    () => localStorage.getItem('selectedTechIndicatorSlug') || ''
  );
  const [reportSubMenuVisible, setReportSubMenuVisible] = useState(() => localStorage.getItem('reportSubMenuVisible') === 'true');
  const [techIndicatorsSubMenuVisible, setTechIndicatorsSubMenuVisible] = useState(
    () => localStorage.getItem('techIndicatorsSubMenuVisible') === 'true'
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [techIndicatorsList, setTechIndicatorsList] = useState([]);

  const reportId = (() => {
    const path = location.pathname;
    const match = path.match(/\/reports\/([^/]+)/) ||  path.match(/\/keyFindings\/([^/]+)/) ||  path.match(/\/techIndicator\/([^/]+)/);
    if (match) {
      return match[1];
    } else {
      return lastReportId;
    }
  })();

  const updateSelectedMenu = (path) => {
    if (path === '/assessment' || path === '/upload' || path === '/generateReport') {
      setSelected(1);
      setReportSubMenuVisible(false);
      toggleSubSidebarOthers()
    } else if (path === '/user' || path === '/createUser' || path.startsWith('/editUser')) {
      setSelected(3);
      toggleSubSidebarOthers()
      setReportSubMenuVisible(false);
    } else if (path === '/admintools') {
      setSelected(2);
      setReportSubMenuVisible(false);
      toggleSubSidebarOthers()
    } else if (path.startsWith('/profile')) {
      isAdmin ? setSelected(4) : setSelected(2);
      setReportSubMenuVisible(false);
      toggleSubSidebarOthers()
    } else if (path.startsWith('/feedback')) {
      isAdmin ? setSelected(5) : setSelected(3);
      setReportSubMenuVisible(false);
      toggleSubSidebarOthers()
    } else if (path.match(/\/reports\/([^/]+)/)) {
      setSelected(0);
      setSelectedsub(0);
      setReportSubMenuVisible(true);
      window.innerWidth <= 991 ? toggleSubSidebarOthers() : toggleSubSidebar();
    } else if (path.match(/\/keyFindings\/([^/]+)/)) {
      setSelected(0);
      setSelectedsub(1);
    } else if (path.match(/\/techIndicator\/([^/]+)/)) {
      setSelected(0); // Report Management
      setSelectedsub(2); 
      const techIndicatorSlug = path.split('/').pop();
      setSelectedTechIndicatorSlug(techIndicatorSlug);
    } else {
      setSelected(0);
      setReportSubMenuVisible(false);
      toggleSubSidebarOthers()
    }
  };
  

  useEffect(() => {
    const handleResize = () => {
      const pathsToCheck = [
        '/reports',
        '/techIndicator/',
        '/keyFindings',
      ];

      const shouldToggle = pathsToCheck.some((path) => location.pathname.startsWith(path));

      if (shouldToggle) {
        window.innerWidth <= 991 ? toggleSubSidebarOthers() : toggleSubSidebar();
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location.pathname, toggleSubSidebar, toggleSubSidebarOthers]);

  useEffect(() => {
    updateSelectedMenu(location.pathname);
  }, [location.pathname, techIndicatorsList]);

  useEffect(() => {
    localStorage.setItem('selectedMenuIndex', selected);
  }, [selected]);

  useEffect(() => {
    localStorage.setItem('selectedSubMenuIndex', selectedsub);
  }, [selectedsub]);

  useEffect(() => {
    localStorage.setItem('selectedTechIndicatorSlug', selectedTechIndicatorSlug);
  }, [selectedTechIndicatorSlug]);

  useEffect(() => {
    localStorage.setItem('reportSubMenuVisible', reportSubMenuVisible);
  }, [reportSubMenuVisible]);

  useEffect(() => {
    localStorage.setItem('techIndicatorsSubMenuVisible', techIndicatorsSubMenuVisible);
  }, [techIndicatorsSubMenuVisible]);

  const menuItems = [
    { text: 'Homepage', route: '/pastAssessment', icon: HomeIcon },
    { text: 'Create Assessment', route: '/assessment', icon: createAssessment },
    // { text: 'Report Management', route: `/reports/${reportId}`, icon: reportManagement, hasSubMenu: true, isSubMenuReport: true },
    ...(isAdmin ? [{ text: 'Admin Tools', route: '/admintools', icon: reportManagement }] : []),
    ...(isAdmin ? [{ text: 'User Management', route: '/user', icon: user }] : []),
    { text: 'My profile', route: '/profile', icon: profileIcon },
    { text: 'Feedback', route: '/feedback', icon: feedback },
  ];

  const reportSubMenuItems = [
    { text: 'Reports', route: `/reports/${reportId}`, icon: reports },
    { text: 'Key Strengths & Findings', route: `/keyFindings/${reportId}`, icon: assessmentFindings },
    {
      text: 'Tech Indicators',
      route: `/techIndicator/${reportId}`,
      icon: techIndicators,
      // hasSubMenu: true,
      // isSubMenuTech: true,
      // subMenu: techIndicatorsList.map((item) => ({
      //   text: item.name,
      //   route: `/techIndicator/${reportId}/${item.indicator}`,
      //   slug: item.indicator,
      // })),
    },
  ];

  const handleMenuItemClick = (index, item) => {
    setSelected(index);
    if (item.hasSubMenu) {
      toggleSubSidebar();
      setReportSubMenuVisible(true);
      setSelectedsub(0);
      navigate(item.route); // Navigate to the item's route
    } else {
      toggleSubSidebarOthers();
      navigate(item.route);
      setReportSubMenuVisible(false);
      setTechIndicatorsSubMenuVisible(false);
      onClose();
    }
  };

  const handleSubMenuClick = (subIndex, subItem) => {
    setSelectedsub(subIndex);
    if (subItem.hasSubMenu) {
      setTechIndicatorsSubMenuVisible(!techIndicatorsSubMenuVisible);
    } else {
      navigate(subItem.route);
      setTechIndicatorsSubMenuVisible(false);
      onClose();
    }
  };

  const handleNestedMenuClick = (nestedItem) => {
    setSelectedTechIndicatorSlug(nestedItem.slug);
    navigate(nestedItem.route);
    onClose();
  };

  return (
    <ChakraProvider>
      <Box
        display={{ base: 'none', lg: 'block' }}
        bg="#F5F8FF"
        w={isCollapsed ? '70px' : '255px'}
        h="100%"
        py={4}
        borderRight="2px solid #F5F8FF"
        position={'fixed'}
        top={0}
        left={0}
        transition="width 0.3s ease"
      >
        <Box display="flex" justifyContent="space-between" px={3} pt={2}>
          {!isCollapsed && (
            <Box cursor="pointer" onClick={() => navigate('/pastAssessment')} pl="10px">
              <Text color="Black" fontWeight="medium" fontSize="20px" isTruncated>
                MSS
              </Text>
            </Box>
          )}
          <IconButton
            aria-label="Toggle Sidebar"
            icon={
              isCollapsed ? (
                <LastPageIcon style={{ color: '#004EEB' }} />
              ) : (
                <FirstPageIcon style={{ color: '#004EEB' }} />
              )
            }
            onClick={toggleSidebar}
            variant="ghost"
            size="sm"
          />
        </Box>
        <br />
        <Box w="100%" h="90%" display="flex" flexDirection="column" justifyContent="space-between">
          <Box p={!isCollapsed ? '20px' : '10px'}>
            <VStack align="stretch" spacing={2}>
              {menuItems.map((item, index) => (
                <MenuItem
                  key={item.text}
                  text={item.text}
                  icon={item.icon}
                  isSelected={selected === index}
                  onClick={() => handleMenuItemClick(index, item)}
                  isCollapsed={isCollapsed}
                  hasSubMenu={item.hasSubMenu}
                  isSubMenuOpen={reportSubMenuVisible}
                />
              ))}
            </VStack>
          </Box>
          <Box p={isCollapsed ? '9px' : 5}>
            <Box
              as="button"
              border="2px solid #FECDCA"
              borderRadius="16px"
              mb={1}
              width="fit-content"
              backgroundColor={'white'}
              _hover={{ bg: '#fEE4E2' }}
              _active={{ bg: '#fEE4E2' }}
              onClick={() => logout()}
            >
              <Box
                py={isCollapsed ? 3 : 2}
                px={isCollapsed ? 3 : 6}
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                <Icon as={LogoutIcon} width="20px" height="20px" fill="#F04438" />
                {!isCollapsed && (
                  <Text pl={2} color="#F04438" fontSize="14px" fontWeight="medium">
                    {' '}
                    Logout
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {reportSubMenuVisible && (
          <Box
            bg="#EFF4FF"
            w={isCollapsed ? '250px' : '255px'}
            h="100%"
            py={4}
            borderRight="2px solid #F5F8FF"
            position={'fixed'}
            top={0}
            left={isCollapsed ? '70px' : '255px'}
            transition="left 0.3s ease, width 0.3s ease"
          >
            <Box p="20px">
              <VStack align="stretch">
                {reportSubMenuItems.map((subItem, subIndex) => (
                  <React.Fragment key={subItem.text}>
                    <MenuItem
                      text={subItem.text}
                      icon={subItem.icon}
                      isSelected={selectedsub === subIndex}
                      onClick={() => handleSubMenuClick(subIndex, subItem)}
                      hasSubMenu={subItem.hasSubMenu}
                      isSubMenuOpen={techIndicatorsSubMenuVisible}
                      isSubMenuTech={subItem.isSubMenuTech}
                      selectedsub={selectedsub}
                      selectedarrow={selectedsub === 2}
                    />
                    {subItem.hasSubMenu && techIndicatorsSubMenuVisible && (
                      <VStack align="stretch" pl={isCollapsed ? 0 : 0}>
                        {subItem.subMenu.map((nestedItem) => (
                          <MenuItem
                            key={nestedItem.slug}
                            text={nestedItem.text}
                            isSelectedc={selectedTechIndicatorSlug === nestedItem.slug}
                            onClick={() => handleNestedMenuClick(nestedItem)}
                          />
                        ))}
                      </VStack>
                    )}
                  </React.Fragment>
                ))}
              </VStack>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        bg="#F5F8FF"
        w="100%"
        py={2}
        px={4}
        borderBottom="1px solid #E2E8F0"
        position="fixed"
        top={0}
        left={0}
        zIndex="999"
        height="48px"
        display={{ base: 'flex', lg: 'none' }}
        alignItems="center"
        justifyContent="flex-start"
      >
        <IconButton
          aria-label="Open Menu"
          icon={<MenuIcon />}
          onClick={onOpen}
          variant="ghost"
          size="lg"
          style={{ color: '#5A5A5A', fontSize: '30px' }}
        />
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton color="#2970FF" />
              <DrawerHeader>MSS</DrawerHeader>
              <DrawerBody>
                <VStack align="stretch">
                  {menuItems.map((item, index) => (
                    <React.Fragment key={item.text}>
                      <MenuItem
                        text={item.text}
                        icon={item.icon}
                        isSelected={selected === index}
                        onClick={() => handleMenuItemClick(index, item)}
                        isCollapsed={false}
                        hasSubMenu={item.hasSubMenu}
                        isSubMenuOpen={reportSubMenuVisible}
                        isSubMenuReport={item.isSubMenuReport}
                        selectedarrowR={selected === 2}
                      />
                      {item.hasSubMenu && reportSubMenuVisible && (
                        <VStack align="stretch" pl={4}>
                          {reportSubMenuItems.map((subItem, subIndex) => (
                            <React.Fragment key={subItem.text}>
                              <MenuItem
                                text={subItem.text}
                                icon={subItem.icon}
                                isSelected={selectedsub === subIndex}
                                onClick={() => handleSubMenuClick(subIndex, subItem)}
                                hasSubMenu={subItem.hasSubMenu}
                                isSubMenuOpen={techIndicatorsSubMenuVisible}
                                isSubMenuTech={subItem.isSubMenuTech}
                                selectedsub={selectedsub}
                                selectedarrow={selectedsub === 2}
                              />
                              {subItem.hasSubMenu && techIndicatorsSubMenuVisible && (
                                <VStack align="stretch" pl={8}>
                                  {subItem.subMenu.map((nestedItem) => (
                                    <MenuItem
                                      key={nestedItem.slug}
                                      text={nestedItem.text}
                                      isSelectedc={selectedTechIndicatorSlug === nestedItem.slug}
                                      onClick={() => handleNestedMenuClick(nestedItem)}
                                    />
                                  ))}
                                </VStack>
                              )}
                            </React.Fragment>
                          ))}
                        </VStack>
                      )}
                    </React.Fragment>
                  ))}
                </VStack>
              </DrawerBody>
              <DrawerFooter display="flex" justifyContent="start" alignItems="center">
                <Box
                  as="button"
                  border="2px solid #FECDCA"
                  borderRadius="16px"
                  mb={1}
                  width="fit-content"
                  backgroundColor={'white'}
                  _hover={{ bg: '#fEE4E2' }}
                  _active={{ bg: '#fEE4E2' }}
                  onClick={() => {
                    logout();
                    onClose();
                  }}
                >
                  <Box py={2} px={6} display="flex" justifyContent="start" alignItems="center">
                    <Icon as={LogoutIcon} width="20px" height="20px" fill="#F04438" />
                    <Text pl={3} color="#F04438" fontSize="14px" fontWeight="medium">
                      {' '}
                      Logout
                    </Text>
                  </Box>
                </Box>
              </DrawerFooter>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
    </ChakraProvider>
  );
};

export default Sidebar;
