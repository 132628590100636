import React, { useState, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import Login from '../pages/login/login';
import Resetpassword from '../pages/login/resetpassword';
import Otp from '../pages/login/otp';
import Newpassword from '../pages/login/newpassword';
import Account from '../pages/account/account';
import Feedback from '../pages/feedback/feedback';
import Allfeedback from '../pages/feedback/feedbackadmin';
import Pastassessment from '../pages/homePage/homepage';
import Assessment from '../pages/createAssessment/createAssessment';
import Upload from '../pages/createAssessment/uploadGenerateReport';
import Report from '../pages/report/report';
import Keyfinding from '../pages/report/keyfindings';
import TechIndicator from '../pages/report/techIndicator';
import Createuser from '../pages/user/createuser';
import Edituser from '../pages/user/edituser';
import AdminTool from '../pages/adminTools/adminTool';
import Alluser from '../pages/user/alluser';
import ProtectedRoute from './ProtectedRoute';
import ScrollToTop from '../hooks/scrollToTop';
import { get } from '../services/api';

const noSidebarRoutes = ['/login', '/forgetPassword', '/otp', '/resetPassword'];

const MainLayout = () => {
  const location = useLocation();
  const shouldRenderSidebar = !noSidebarRoutes.includes(location.pathname);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [subsidebar, setSubsidebar] = useState(false);
  const [email, setEmail] = useState('');
  const [reportId, setReportId] = useState(1)

  React.useEffect(() => {
    if (noSidebarRoutes.includes(location.pathname)) {
      setSubsidebar(false);
    }
  }, [location.pathname]);

  const contentRef = useRef(null);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggleSubSidebar = () => {
    setSubsidebar(true);
  };
  const toggleSubSidebarOthers = () => {
    setSubsidebar(false);
  };

  return (
    <div className="App">
      {shouldRenderSidebar && (
        <Sidebar
          isCollapsed={isCollapsed}
          toggleSidebar={toggleSidebar}
          toggleSubSidebar={toggleSubSidebar}
          toggleSubSidebarOthers={toggleSubSidebarOthers}
          lastReportId = {reportId}
        />
      )}
      <div
        className={`content ${
          !shouldRenderSidebar
            ? 'without-sidebar'
            : subsidebar
            ? isCollapsed
              ? 'with-subsidebar-collapsed'
              : 'with-subsidebar-expanded'
            : isCollapsed
            ? 'collapsed-sidebar'
            : 'with-sidebar'
        }`}
        ref={contentRef}
      > 
        <ScrollToTop containerRef={contentRef} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgetPassword" element={<Resetpassword setEmail={setEmail} />} />
          <Route path="/otp" element={<Otp email={email} />} />
          <Route path="/resetPassword" element={<Newpassword email={email} />} />
          <Route path="/account" element={<ProtectedRoute adminComponent={Account} allowedRoles={['Admin']}  />} />
          <Route path="/feedback" element={<ProtectedRoute userComponent={Feedback} adminComponent={Allfeedback} allowedRoles={['User', 'Admin']} />} />
          <Route path="/pastAssessment" element={<ProtectedRoute userComponent={Pastassessment} adminComponent={Pastassessment} allowedRoles={['User', 'Admin']}/>} />
          <Route path="/assessment" element={<ProtectedRoute adminComponent={Assessment} userComponent={Assessment} allowedRoles={['User','Admin']} />} />
          <Route path="/upload" element={<ProtectedRoute userComponent={Upload} adminComponent={Upload} allowedRoles={['User', 'Admin']} />} />
          <Route path="/reports/:reportId" element={<ProtectedRoute adminComponent={Report} userComponent={Report} allowedRoles={['User', 'Admin']} />} />
          <Route path="/keyFindings/:reportId" element={<ProtectedRoute adminComponent={Keyfinding} userComponent={Keyfinding} allowedRoles={['User', 'Admin']}/>} />
          <Route path="/techIndicator/:reportId" element={ <ProtectedRoute adminComponent={TechIndicator} userComponent={TechIndicator} allowedRoles={['User', 'Admin']} /> } />
          <Route path="/user" element={<ProtectedRoute adminComponent={Alluser} allowedRoles={['Admin']} />} />
          <Route path="/profile" element={<ProtectedRoute userComponent={Account} adminComponent={Account} allowedRoles={['User', 'Admin']} />} />
          <Route path="/createUser" element={<ProtectedRoute adminComponent={Createuser} allowedRoles={['Admin']}/>} />
          <Route path="/editUser/:userId" element={<ProtectedRoute adminComponent={Edituser} allowedRoles={['Admin']}/>} />
          <Route path="/admintools" element={<ProtectedRoute adminComponent={AdminTool} allowedRoles={['Admin']}/>} />
        </Routes>
      </div>
    </div>
  );
};

export default MainLayout;
