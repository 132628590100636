
export const validateField = (field, value, values) => {
  let error = '';

  switch (field) {
    case 'name':
      const nameRegex = /^[a-zA-Z .'-]+( [a-zA-Z .'-]+){0,29}$/;
      if (!value) {
        error = 'Name is required';
      } else if (!nameRegex.test(value)) {
        error = 'Name is invalid';
      }
      break;
    case 'phoneNumber':
      const phoneRegex = /^(\+?\d{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?){1,3}\d{1,4}$/;
      if (!value) {
        error = 'Phone number is required';
      } else if (!phoneRegex.test(value)) {
        error = 'Phone number is invalid';
      }
      break;
    case 'email':
      const emailRegex = /^(?!.*(\.\.|@\.|\.@|@-))^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!value) {
        error = 'Email is required';
      } else if (!emailRegex.test(value)) {
        error = 'Email is invalid';
      }
      break;
    case 'companyName':
      const companyNameRegex = /^[a-zA-Z0-9]+( +[a-zA-Z0-9]+){0,49}$/;
      if (!value) {
        error = 'Company name is required';
      } else if (!companyNameRegex.test(value)) {
        error = 'Company name is invalid';
      }
      break;
    case 'numberOfEmployees':
      if (!value) {
        error = 'Company size is required';
      } else if (isNaN(value)) {
        error = 'Company size must be a number';
      } else if (value <= 0) {
        error = 'Company size must be greater than 0';
      }
      break;
    case 'password':
      if (!value) {
        error = 'Password is required';
      } else if (value.length < 6) {
        error = 'Password must be at least 6 characters';
      }
      break;
    case 'confirmPassword':
      if (!value) {
        error = 'Confirm password is required';
      } else if (value !== values.password) {
        error = 'Passwords do not match';
      }
      break;
    case 'industryType':
      if (value === '') {
        error = 'Industry type is required';
      }
      break;
    case 'role':
      if (value === '') {
        error = 'Role is required';
      }
      break;
    default:
      break;
  }
  return error;
};

export default validateField;
  