import '../../App.css';
import React from "react";
import { ChakraProvider } from '@chakra-ui/react';
import { HStack, PinInput, PinInputField, Button, Text, Box as ChakraBox, Heading, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Otp({ email }) {
  const [otp, setOtp] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [isOtpVerified, setIsOtpVerified] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const validate = (field, value) => {
    let error = '';

    switch (field) {
      case 'otp':
        const otpRegex = /^\d{5}$/;
        if (!value) {
          error = 'OTP is required';
        } else if (!otpRegex.test(value)) {
          error = 'OTP is invalid';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    return error;
  };

  const handleBlur = (field) => (e) => {
    validate(field, e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = ['otp'];
    const validationErrors = {};

    fields.forEach((field) => {
      const value = eval(field);
      const error = validate(field, value);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const data = {
        emailId: email,
        otp: parseInt(otp, 10),
      };
      setIsLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/verify-otp`, data);

        setIsOtpVerified(true);
        setTimeout(() => {
          setOtp('');
          navigate('/resetPassword');
        }, 1000);
      } catch (error) {
        console.error('Error submitting form:', error);
        if (error.response && error.response.data.message === "Invalid OTP") {
          setErrors({ otp: 'Wrong OTP, try entering again' });
          setIsOtpVerified(false);
        } else if (error.response && error.response.data.message === "OTP is expired") {
          setErrors({ otp: 'OTP is expired' });
          setIsOtpVerified(false);
        } else {
          setErrors({ otp: 'An unexpected error occurred' });
          setIsOtpVerified(false);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="login">
      <div className='rounded-box'>
        <ChakraProvider>
          <Heading as="h1" fontWeight='700' fontSize='24px' color={'black'}>Enter OTP</Heading>
          <br />
          <Text as="h6" fontWeight='500' fontSize='16px' color={'#00359E'}>Enter the OTP that will be received on {email}</Text>
          <br />
          <ChakraBox textAlign={'left'} width={'100%'} >
          <form onSubmit={handleSubmit}>
            <Text fontSize='14px' fontWeight='500' color='#111927' mb="3">
              Enter OTP
            </Text>
            <HStack spacing="1rem" width="100%" display='flex' flexDirection='row' justifyContent='center'>
              <PinInput otp placeholder='' value={otp} borderColor={errors.otp ? '#F04438' : isOtpVerified ? '#12B76A' : '#F3F4F6'} focusBorderColor={errors.otp ? '#F04438' : isOtpVerified ? '#12B76A' : '#2970FF'} width="100%" onChange={(value) => setOtp(value)} onBlur={handleBlur('otp')}>
                <PinInputField borderColor={errors.otp ? '#F04438' : isOtpVerified ? '#12B76A' : '#F3F4F6'} color={errors.otp ? '#B42318' : isOtpVerified ? '#027A48' : '#2970FF'} width="15%" height="50px" bg='white' fontSize="23px" fontWeight='500'/>
                <PinInputField borderColor={errors.otp ? '#F04438' : isOtpVerified ? '#12B76A' : '#F3F4F6'} color={errors.otp ? '#B42318' : isOtpVerified ? '#027A48' : '#2970FF'} width="15%" height="50px" bg='white' fontSize="23px" fontWeight='500'/>
                <PinInputField borderColor={errors.otp ? '#F04438' : isOtpVerified ? '#12B76A' : '#F3F4F6'} color={errors.otp ? '#B42318' : isOtpVerified ? '#027A48' : '#2970FF'} width="15%" height="50px" bg='white' fontSize="23px" fontWeight='500'/>
                <PinInputField borderColor={errors.otp ? '#F04438' : isOtpVerified ? '#12B76A' : '#F3F4F6'} color={errors.otp ? '#B42318' : isOtpVerified ? '#027A48' : '#2970FF'} width="15%" height="50px" bg='white' fontSize="23px" fontWeight='500'/>
                <PinInputField borderColor={errors.otp ? '#F04438' : isOtpVerified ? '#12B76A' : '#F3F4F6'} color={errors.otp ? '#B42318' : isOtpVerified ? '#027A48' : '#2970FF'} width="15%" height="50px" bg='white' fontSize="23px" fontWeight='500'/>
              </PinInput>
            </HStack>
            {errors.otp && <Text color="#F04438" fontSize="16px" fontWeight='400' >{errors.otp}</Text>}
            {isOtpVerified && <Text color="#12B76A" fontSize="16px" fontWeight='400'>OTP is verified successfully</Text>}
            <br />
            <Button type="submit" bg="#2970FF" color='white' variant='solid' width={'100%'} _hover={{ bg: '#7044c4' }} mt="6">
            {isLoading ? <Spinner size="sm" mr="2" /> : null} Verify OTP
            </Button>
            </form>
            <ChakraBox mt="5">
              <Text textAlign='center' bg={'#EFF4FF'} fontSize='14px' fontWeight="400" color='#000000' cursor='pointer' onClick={() => navigate('/login')}>
                Back to <span style={{ color:'#00359E' }}> signIn </span>
              </Text> 
            </ChakraBox>
          </ChakraBox>
        </ChakraProvider>
      </div>
    </div>
  );
}

export default Otp;
